import React from 'react'
import Banner from '../Banner'
import BannerImg from "../../asset/img/banner/page-title.jpg"
import Story from '../Layout/Story'
import Header from '../Header/Header'


const HistoryPage = () => {
  return (
    <>
    <Header/>
    <Banner img={BannerImg} heading="History" name="History"/>
    <Story/>
    </>
  )
}

export default HistoryPage