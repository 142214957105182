import React from "react";
import TopNav from "./TopNav"
import Nav from "../Nav";

const Header = (props) => {
  return (
    <>
         <header className={props.header}>
        <TopNav/>
        <Nav nav="navbar-default " 
          search="top-search bg-black-opacity-light"
          />
        </header>
    </>
  );
};

export default Header;
