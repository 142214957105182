import React from 'react'
import { Link } from 'react-router-dom'



const Banner = (props) => {
  return (
    <>
    <section className="page-title-section bg-img cover-background" data-overlay-dark="75"  style={{ backgroundImage: `url(${props.img})` }}>
            <div className="container">

                <div className="row">
                    <div className="col-md-12">
                        <h1>{props.heading}</h1>
                    </div>
                    <div className="col-md-12">
                        <ul>
                            <li><Link>Home</Link></li>
                            <li><Link>{props.name}</Link></li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </>
  )
}

export default Banner