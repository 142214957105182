import React from 'react'
import ServiceCard from '../ServiceCard'
import Service1Img from "../../asset/img/services/01.jpg"

const Services = () => {
  return (
   <>
    <section style={{padding:"10px"}}>
            <div className="container">
  <div className="margin-50px-bottom xs-margin-30px-bottom text-center">
                    <h3 className="font-size13 text-uppercase margin-15px-bottom md-margin-10px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">What We Do</h3>
                    <h4 className="font-size38 sm-font-size34 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">Latest Services</h4>
                </div>
                <div className="row">
                  <ServiceCard  img={Service1Img} title="Building Construction "  name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"/>
                  <ServiceCard  img={Service1Img} title="Building Construction "  name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"/>
                  <ServiceCard  img={Service1Img} title="Building Construction "  name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"/>
                  <ServiceCard  img={Service1Img} title="Building Construction "  name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"/>
                  <ServiceCard  img={Service1Img} title="Building Construction "  name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"/>
                  <ServiceCard  img={Service1Img} title="Building Construction "  name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"/>
                    
                 

                  

                  
                </div>
            </div>
        </section>
   </>
  )
}

export default Services