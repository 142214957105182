import React from "react";
import Banner from "../../asset/img/banner/page-title.jpg";
import ServiceCard from "../ServiceCard";
import Service1Img from "../../asset/img/services/01.jpg";
import { Link } from "react-router-dom";
import Header from "../Header/Header";

const ServicePage = () => {
  return (
    <>
    <Header/>
      <section
        class="page-title-section bg-img cover-background"
        data-overlay-dark="75"
        style={{
          backgroundImage: `url(${Banner})`,
          // Adjust the margin-top value as needed
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1>Our Services</h1>
            </div>
            <div class="col-md-12">
              <ul>
                <li>
                    <Link>Home</Link>
                 
                </li>
                <li>
                    <Link>
                    Services
                    </Link>
                
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="margin-50px-bottom xs-margin-30px-bottom text-center">
            <h3 className="font-size13 text-uppercase margin-15px-bottom md-margin-10px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">
              What We Do
            </h3>
            <h4 className="font-size38 sm-font-size34 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
              Latest Services
            </h4>
          </div>
          <div className="row">
            <ServiceCard
              img={Service1Img}
              title="Building Construction "
              name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
            />
            <ServiceCard
              img={Service1Img}
              title="Building Construction "
              name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
            />
            <ServiceCard
              img={Service1Img}
              title="Building Construction "
              name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
            />
            <ServiceCard
              img={Service1Img}
              title="Building Construction "
              name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
            />
            <ServiceCard
              img={Service1Img}
              title="Building Construction "
              name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
            />
            <ServiceCard
              img={Service1Img}
              title="Building Construction "
              name="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicePage;
