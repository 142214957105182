import React from "react";

const CounterCard = (props) => {
  return (
    <>
      <div className="col-md-4 xs-margin-25px-bottom">
        <div className="counter-block text-center">
          <h4 className="margin-10px-bottom text-theme-color">
            <span className="countup">{props.number}</span>
          </h4>
          <div className="separator"></div>
          <h5 className="margin-5px-bottom xs-no-margin-bottom text-uppercase font-weight-500 font-size20 sm-font-size18 xs-font-size16">
            {props.name}
          </h5>
          <p className="no-margin-bottom">{props.industry}</p>
        </div>
      </div>
    </>
  );
};

export default CounterCard;
