import React from "react";
import BannerSection from "./BannerSection";
import AboutService from "./AboutService";
import Services from "./Services";

import Counter from "./Counter";
import Testimonial from "./Testimonial";
import Blog from "./Blog";
import Story from "./Story";
import Partnership from "./Partnership";
import Header from "../Header/Header";
import { Prodcut } from "./Prodcut";

const Layout = () => {
  return (
    <>

      <Header  header="position-absolute width-100 transparent-header"/>

      <BannerSection />
      <AboutService />
      <Services />
      <Prodcut/>
      <Counter />
      <Testimonial />
      <Blog />
      <Story />
      <Partnership />
    </>
  );
};

export default Layout;
