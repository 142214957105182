import React from "react";
import Logo from "../asset/img/Logo/651e8bea61a94.png";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Nav = (props) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      {/* <div className="navbar-default"> */}
      <div className={props.nav}>
        {/* <div className="top-search bg-black-opacity-light"> */}

        <div className={props.search}>
          <div className="container">
            <form
              className="search-form"
              action="https://onesixninecreative.com.au/kc2/search.html"
              method="GET"
              acceptCharset="utf-8"
            >
              <div className="input-group">
                <span className="input-group-addon cursor-pointer">
                  <button
                    className="search-form_submit fas fa-search font-size18 text-white "
                    type="submit"
                  ></button>
                </span>
                <input
                  type="text"
                  className="search-form_input form-control"
                  name="s"
                  autoComplete="off"
                  placeholder="Type & hit enter..."
                />
                <span className="input-group-addon close-search">
                  <i className="fas fa-times font-size18 margin-5px-top"></i>
                </span>
              </div>
            </form>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12">
              <div className="menu_area alt-font">
                <nav className="navbar navbar-expand-lg navbar-light no-padding">
                  <div className="navbar-header navbar-header-custom ">
                    <Link className="navbar-brand" to="/" onClick={scrollToTop}>
                      <img
                        id="logo"
                        src={Logo}
                        alt="logo "
                        style={{
                          border: "1px solid #000000b5",
                          borderRadius: "5px",
                          backgroundColor: "black",
                          height: "80px",
                          maxHeight: "60px",
                        }}
                      />
                    </Link>
                  </div>

                  <div className="navbar-toggler"></div>

                  <ul
                    className="navbar-nav ml-auto"
                    id="nav"
                    style={{ display: "none;" }}
                  >
                    <li>
                      <Link to="/" onClick={scrollToTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className="has-sub">
                      <span className="submenu-button"></span>
                      <Link>Services</Link>

                      <ul className="sub-menu">
                        <li>
                          <Link to="/service1">Service 1</Link>
                        </li>

                        <li>
                          <Link to="/service2">Service 2</Link>
                        </li>
                        <li>
                          <Link to="/service3">Service 3</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-sub">
                      <span className="submenu-button"></span>
                      <Link>Products</Link>

                      <ul className="sub-menu">
                        <li>
                          <Link to="/ticket">Ticket Management System</Link>
                        </li>
                        <li>
                          <Link to="/desk">Desk Management System</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/career">Career</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>

                  <div className="attr-nav sm-no-margin sm-margin-70px-right xs-margin-65px-right">
                    {/* <ul>
                      <li className="search">
                        <Link>
                          <i className="fas fa-search"></i>
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
