import React from 'react'
import Header from '../Header/Header'
import banner1 from "../../asset/img/banner/page-title.jpg"
import ServiceImg from "../../asset/img/services/01.jpg"
import Banner from '../Banner'


const Service1 = () => {
  return (
    <>
    <Header/>
    <Banner img={banner1} heading="Service 1" name="Service 1"/>
    <div className="container mt-5">
    <div className="row">
      <div className="col-md-6">
        <h2>Our Services</h2>
        <ul className="list-group">
          <li className="list-group-item">Web Development: Our expert team of developers will create stunning and responsive websites tailored to your needs.</li>
          <li className="list-group-item">Mobile App Development: Take your business to the next level with our custom mobile app development services.</li>
          <li className="list-group-item">Graphic Design: Make a lasting impression with captivating visuals crafted by our talented graphic designers.</li>
          <li className="list-group-item">Digital Marketing: Boost your online presence and reach your target audience effectively with our digital marketing solutions.</li>
          <li className="list-group-item">Content Writing: Engage your audience and drive results with compelling content created by our skilled writers.</li>
          <li className="list-group-item">IT Consulting: Get expert guidance and advice on all your IT needs from our experienced consultants.</li>
        </ul>
      </div>
      <div className="col-md-6">
      <img src={ServiceImg} alt="" />
      </div>
      </div>
      </div>
    </>
  )
}

export default Service1