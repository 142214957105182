import React from 'react'
import BlogImg1 from "../../asset/img/blog/01.jpg"
import BlogCard from '../BlogCard'

const Blog = () => {
  return (
   <>
    <section style={{padding:"10px", marginTop:"-20px"}}> 
            <div className="container" >
                <div className="margin-50px-bottom xs-margin-30px-bottom text-center">
                    <h3 className="font-size13 text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">from our blog</h3>
                    <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">News & Events</h4>
                </div>

                <div className="row">
                    <BlogCard Img1={BlogImg1}/>
                    <BlogCard Img1={BlogImg1}/>
                    <BlogCard Img1={BlogImg1}/>
                    {/* <div className="col-lg-4 sm-margin-30px-bottom">
                        <div className="blog-block h-100">
                            <div className="position-relative">
                                <img src={BlogImg1} alt="" />
                            </div>
                            <div className="date">
                                <span className="display-block">07</span>
                                <span className="font-size14 font-weight-400">Mar</span>
                            </div>
                            <div className="blog-text padding-30px-all md-padding-25px-all xs-padding-20px-all">
                                <h5 className="font-size20 md-font-size18 font-weight-500 margin-10px-bottom xs-margin-5px-bottom"><a href="blog-post.html" className="text-black">How to go about being an start-up in a matter.</a></h5>
                                <ul className="blog-list">
                                    <li><i className="fas fa-comments text-theme-color font-size13"></i>Comments</li>
                                    <li><i className="fas fa-user text-theme-color font-size13"></i>Roger</li>
                                </ul>
                                <p className="margin-10px-bottom">Many desktop publishing packages and web page editors now use Lorem Ipsum.</p>
                                <a href="blog-post.html" className="text-black font-weight-500 font-size14">Read More<span className="ti-arrow-right vertical-align-middle font-size12 margin-10px-left"></span></a>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-lg-4 sm-margin-30px-bottom">
                        <div className="blog-block h-100">
                            <div className="position-relative">
                                <img src="img/blog/02.jpg" alt="" />
                            </div>
                            <div className="date">
                                <span className="display-block">20</span>
                                <span className="font-size14 font-weight-400">Feb</span>
                            </div>
                            <div className="blog-text padding-30px-all md-padding-25px-all xs-padding-20px-all">
                                <h5 className="font-size20 md-font-size18 font-weight-500 margin-10px-bottom xs-margin-5px-bottom"><a href="blog-post.html" className="text-black">Take action & grow the profession</a></h5>
                                <ul className="blog-list">
                                    <li><i className="fas fa-comments text-theme-color font-size13"></i>Comments</li>
                                    <li><i className="fas fa-user text-theme-color font-size13"></i>Tom</li>
                                </ul>
                                <p className="margin-10px-bottom">It is a long established fact that a reader will be distracted by the readable.</p>
                                <a href="blog-post.html" className="text-black font-weight-500 font-size14">Read More<span className="ti-arrow-right vertical-align-middle font-size12 margin-10px-left"></span></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="blog-block h-100">
                            <div className="position-relative">
                                <img src="img/blog/03.jpg" alt="" />
                            </div>
                            <div className="date">
                                <span className="display-block">17</span>
                                <span className="font-size14 font-weight-400">Feb</span>
                            </div>
                            <div className="blog-text padding-30px-all md-padding-25px-all xs-padding-20px-all">
                                <h5 className="font-size20 md-font-size18 font-weight-500 margin-10px-bottom xs-margin-5px-bottom"><a href="blog-post.html" className="text-black">High standard work for our clients.</a></h5>
                                <ul className="blog-list">
                                    <li><i className="fas fa-comments text-theme-color font-size13"></i>Comments</li>
                                    <li><i className="fas fa-user text-theme-color font-size13"></i>Randall</li>
                                </ul>
                                <p className="margin-10px-bottom">There are many variations of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                <a href="blog-post.html" className="text-black font-weight-500 font-size14">Read More<span className="ti-arrow-right vertical-align-middle font-size12 margin-10px-left"></span></a>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
        </section>
   </>
  )
}

export default Blog