import React from "react";
import CounterCard from "../CounterCard";

const Counter = () => {
  return (
    <>
      <section className="bg-very-light-gray">
        <div className="container">
          <div className="row align-items-center">
            <CounterCard number="238" name="customers" industry="Printing and typesetting industry"/>
            <CounterCard number="238" name="customers" industry="Printing and typesetting industry"/>
            <CounterCard number="238" name="customers" industry="Printing and typesetting industry"/>

            {/* <div className="col-md-4 xs-margin-25px-bottom">
              <div className="counter-block text-center">
                <h4 className="margin-10px-bottom text-theme-color">
                  <span className="countup">238</span>
                </h4>
                <div className="separator"></div>
                <h5 className="margin-5px-bottom xs-no-margin-bottom text-uppercase font-weight-500 font-size20 sm-font-size18 xs-font-size16">
                  Customers
                </h5>
                <p className="no-margin-bottom">
                  Printing and typesetting industry
                </p>
              </div>
            </div> */}

            {/* <div className="col-md-4 xs-margin-25px-bottom">
              <div className="counter-block text-center">
                <h4 className="margin-10px-bottom text-theme-color">
                  <span className="countup">45</span>
                </h4>
                <div className="separator"></div>
                <h5 className="margin-5px-bottom xs-no-margin-bottom text-uppercase font-weight-500 font-size20 sm-font-size18 xs-font-size16">
                  Years Experience
                </h5>
                <p className="no-margin-bottom">Various versions have evolved</p>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="counter-block text-center">
                <h4 className="margin-10px-bottom text-theme-color">
                  <span className="countup">23</span>
                </h4>
                <div className="separator"></div>
                <h5 className="margin-5px-bottom xs-no-margin-bottom text-uppercase font-weight-500 font-size20 sm-font-size18 xs-font-size16">
                  Projects
                </h5>
                <p className="no-margin-bottom">
                  variations of passages of Lorem Ipsum
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Counter;
