import React from 'react'
import { Link } from 'react-router-dom'


const BlogCard = (props) => {
  return (
   <>
   <div className="col-lg-4 sm-margin-30px-bottom">
                        <div className="blog-block h-100">
                            <div className="position-relative">
                                <img src={props.Img1} alt="" />
                            </div>
                            <div className="date">
                                <span className="display-block">07</span>
                                <span className="font-size14 font-weight-400">Mar</span>
                            </div>
                            <div className="blog-text padding-30px-all md-padding-25px-all xs-padding-20px-all">
                                <h5 className="font-size20 md-font-size18 font-weight-500 margin-10px-bottom xs-margin-5px-bottom"><a href="blog-post.html" className="text-black">How to go about being an start-up in a matter.</a></h5>
                                <ul className="blog-list">
                                    <li><i className="fas fa-comments text-theme-color font-size13"></i>Comments</li>
                                    <li><i className="fas fa-user text-theme-color font-size13"></i>Roger</li>
                                </ul>
                                <p className="margin-10px-bottom">Many desktop publishing packages and web page editors now use Lorem Ipsum.</p>
                                <Link className="text-black font-weight-500 font-size14">Read More<span className="ti-arrow-right vertical-align-middle font-size12 margin-10px-left"></span></Link>
                            </div>
                        </div>
                    </div>
   </>
  )
}

export default BlogCard