import React from "react";
import Banner from "../Banner";
import BannerImg from "../../asset/img/banner/page-title.jpg";
import Blog1 from "../../asset/img/blog/01.jpg"
import Blog2 from "../../asset/img/blog/02.jpg"
import Blog3 from "../../asset/img/blog/03.jpg"
import Sm1 from "../../asset/img/blog/sm-01.jpg"
import Sm2 from "../../asset/img/blog/sm-02.jpg"
import Sm3 from "../../asset/img/blog/sm-03.jpg"
import { Link } from "react-router-dom";
import Header from "../Header/Header";



const BlogPost = () => {
  return (
    <>
    <Header/>
      <Banner img={BannerImg} heading="Blog Post" name="Blog Post" />
      <section className="blogs">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 sm-margin-50px-bottom xs-margin-35px-bottom">
              <div className="posts">
                <div className="post">
                  <div className="post-img position-relative margin-30px-bottom sm-margin-25px-bottom xs-margin-20px-bottom">
                    <Link  className="width-100">
                    <img src={Blog1} alt="Blog1" />
                      <div className="date">
                        <span className="display-block">17</span>
                        <span className="font-size14 font-weight-400">Mar</span>
                      </div>
                    </Link>
                    
                  </div>
                  <div className="content">
                    <div className="post-meta">
                      <div className="post-title">
                        <h5>How to go about being an start-up in a matter.</h5>
                      </div>
                      <ul className="meta">
                        <li>
                          <Link>
                          <i
                              className="fa fa-user text-theme-color"
                              aria-hidden="true"
                            ></i>{" "}
                            Roger
                          </Link>
                          
                        </li>
                        <li>
                          <Link>
                          <i
                              className="fa fa-tags text-theme-color"
                              aria-hidden="true"
                            ></i>{" "}
                            Blog
                          </Link>
                         
                        </li>
                        <li>
                          <Link>
                          <i
                              className="fa fa-comments text-theme-color"
                              aria-hidden="true"
                            ></i>{" "}
                            0 Comments
                          </Link>
                         
                        </li>
                      </ul>
                    </div>
                    <div className="post-cont">
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look even slightly believable. If you are going to
                        use a passage.
                      </p>
                      <div className="special">
                        <p className="margin-15px-bottom xs-margin-10px-bottom font-size26 md-font-size22 xs-font-size18 font-weight-600 text-black">
                          If you are going to use a passage of Lorem Ipsum, you
                          need to be sure.
                        </p>
                        <span className="font-weight-600 font-size16 xs-font-size14">
                          <span className="margin-10px-right">-</span>Martha Araujo
                        </span>
                      </div>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here.
                      </p>
                    </div>
                    <div className="share-post">
                      <span>Share Post</span>
                      <ul>
                        <li>
                          <Link>
                          <i className="fab fa-facebook-f"></i>
                          </Link>
                         
                        </li>
                        <li>
                          <Link>
                          <i className="fab fa-twitter"></i>
                          </Link>
                          
                        </li>
                        <li>
                          <Link>
                          <i className="fab fa-youtube"></i>
                          </Link>
                         
                        </li>
                        <li>
                          <Link>
                          <i className="fab fa-linkedin-in"></i>
                          </Link>
                         
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="comments-area">
                  <div className="title-g margin-30px-bottom">
                    <h3>Comments</h3>
                  </div>
                  <div className="comment-box">
                    <div className="author-thumb">
                      <img
                        src={Blog1}
                        alt=""
                        className="rounded-circle width-85 xs-width-100"
                      />
                    </div>
                    <div className="comment-info">
                      <h6>
                        <Link>
                        Alex Joyrina
                        </Link>
                     
                      </h6>
                      <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                      <div className="reply">
                        <Link>
                        <i className="fa fa-reply" aria-hidden="true"></i> Reply
                        </Link>
                       
                      </div>
                    </div>
                  </div>
                  <div className="comment-box">
                    <div className="author-thumb">
                      <img
                        src={Blog2}
                        alt=""
                        className="rounded-circle width-85 xs-width-100"
                      />
                    </div>
                    <div className="comment-info">
                      <h6>
                        <Link>
                        Jama Karleny
                        </Link>
                       
                      </h6>
                      <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                      <div className="reply">
                        <Link>
                        <i className="fa fa-reply" aria-hidden="true"></i> Reply
                        </Link>
                        
                      </div>
                    </div>
                  </div>
                  <div className="comment-box">
                    <div className="author-thumb">
                      <img
                        src={Blog3}
                        alt=""
                        className="rounded-circle width-85 xs-width-100"
                      />
                    </div>
                    <div className="comment-info">
                      <h6>
                        <Link>
                        Ivonne Drennen
                        </Link>
                     
                      </h6>
                      <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                      <div className="reply">
                        <Link>
                        <i className="fa fa-reply" aria-hidden="true"></i> Reply
                        </Link>
                       
                      </div>
                    </div>
                  </div>
                </div>

                <div className="comment-form">
                  <div className="title-g">
                    <h3>Post a Comment</h3>
                  </div>
                  <form className="form" id="comment-form" method="post">
                    <input
                      type="hidden"
                      name="form-name"
                      value="contact-form"
                    />
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6 margin-15px-bottom xs-no-margin-bottom">
                          <input
                            id="form_name"
                            type="text"
                            name="name"
                            placeholder="Name"
                            required="required"
                          />
                        </div>
                        <div className="col-md-6 margin-15px-bottom xs-no-margin-bottom">
                          <input
                            id="form_email"
                            type="email"
                            name="email"
                            placeholder="Email"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 margin-20px-bottom sm-margin-5px-bottom xs-no-margin-bottom">
                          <textarea
                            id="form_message"
                            name="message"
                            placeholder="Message"
                            rows="4"
                            required="required"
                          ></textarea>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" className="butn medium">
                            <span>Send Message</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="side-bar padding-30px-left md-padding-5px-left sm-no-padding-left">
                <div className="widget search">
                  <form method="post">
                    <input type="hidden" name="form-name" value="form 1" />
                    <input
                      type="search"
                      name="search"
                      placeholder="Type here ..."
                    />
                    <button type="submit" className="butn">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Categories</h6>
                  </div>
                  <ul className="no-margin-bottom">
                    <li>
                      <Link>
                      Manufacturing
                      </Link>
                    
                    </li>
                    <li>
                      <Link>
                      Chemical Research
                      </Link>
                      
                    </li>
                    <li>
                      <Link>
                      Mechanical
                      </Link>
                    </li>
                    <li>
                      <Link>
                      Building Management
                      </Link>
                     
                    </li>
                    <li>
                      <Link>
                      Electronic Materials
                      </Link>
                     
                    </li>
                  </ul>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Recent Posts</h6>
                  </div>
                  <div className="recent-post width-100 border-bottom margin-15px-bottom sm-margin-10px-bottom padding-15px-bottom sm-padding-10px-bottom">
                    <div className="width-30 sm-width-auto float-left">
                      <Link>
                      <img src={Sm1} alt="product thumbnail" />
                      </Link>
                     
                    </div>
                    <div className="width-70 display-inline-block padding-15px-left">
                      <h6 className="font-size14 margin-5px-bottom font-weight-600">
                        <Link>
                        A Non-Emergency Trusted Koncrete services
                        </Link>
                       
                      </h6>
                      <span className="font-size13">February 18, 2020</span>
                    </div>
                  </div>

                  <div className="recent-post width-100 border-bottom margin-15px-bottom sm-margin-10px-bottom padding-15px-bottom sm-padding-10px-bottom">
                    <div className="width-30 sm-width-auto float-left">
                      <Link>
                      <img src={Sm2} alt="product thumbnail" />
                      </Link>
                      
                    </div>
                    <div className="width-70 display-inline-block padding-15px-left">
                      <h6 className="font-size14 margin-5px-bottom">
                        <Link>
                        Working for the Koncrete service is much more
                        </Link>
                      
                      </h6>
                      <span className="font-size13">january 24, 2020</span>
                    </div>
                  </div>

                  <div className="recent-post width-100">
                    <div className="width-30 sm-width-auto float-left">
                      <Link>
                      <img src={Sm3} alt="product thumbnail" />
                      </Link>
                    
                    </div>
                    <div className="width-70 display-inline-block padding-15px-left">
                      <h6 className="font-size14 margin-5px-bottom">
                        <Link>
                        Trusted, International Air Koncrete Company
                        </Link>

                      </h6>
                      <span className="font-size13">January 28, 2020</span>
                    </div>
                  </div>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Archives</h6>
                  </div>
                  <ul className="no-margin-bottom">
                    <li>
                      <Link>
                      January 2020
                      </Link>
                    
                    </li>
                    <li>
                      <Link>
                      February 2020
                      </Link>
                      
                    </li>
                    <li>
                      <Link>
                      March 2020
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Follow Us</h6>
                  </div>
                  <ul className="social-listing">
                    <li>
                      <Link>
                      <i className="fab fa-facebook-f"></i>
                      </Link>
                     
                    </li>
                    <li>
                      <Link>
                      <i className="fab fa-twitter"></i>
                      </Link>
                      
                    </li>
                    <li>
                      <Link>
                      <i className="fab fa-youtube"></i>
                      </Link>
                      
                    </li>
                    <li>
                      <Link>
                      <i className="fab fa-linkedin-in"></i>
                      </Link>
                      
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPost;
