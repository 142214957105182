import React from 'react'
import BlogCard from '../BlogCard'
import BlogImg1 from "../../asset/img/blog/01.jpg"
import Banner from '../Banner'
import Bg1 from "../../asset/img/banner/page-title.jpg"
import Header from '../Header/Header'



const BlogGrid = () => {
  return (
   <>
   <Header/>
    <Banner img={Bg1} heading="Blog Grid" name="Blog Grid"/>
    <div className="row">
        <BlogCard Img1={BlogImg1}/>
        <BlogCard Img1={BlogImg1}/>
        <BlogCard Img1={BlogImg1}/>
        </div>
   </>
  )
}

export default BlogGrid