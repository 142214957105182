import React from "react";
import  TestImg1 from "../../asset/img/testmonials/t-1.jpg"
import  TestImg2 from "../../asset/img/testmonials/t-2.jpg"
import  TestImg3 from "../../asset/img/testmonials/t-3.jpg"

const Testimonial = () => {
  return (
    <>
      <section>
        <div className="container lg-container">
          <div className="margin-50px-bottom xs-margin-30px-bottom text-center">
            <h3 className="font-size13 text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">
              testimonials
            </h3>
            <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
              What our clients say
            </h4>
          </div>

          <div className="owl-carousel owl-theme client" style={{display:"flex"}}>
            <div className="client-block">
              <div className="icon">
                <i className="fa fa-quote-right"></i>
              </div>
              <div className="client-img">
                <img src={TestImg1} alt="" />
              </div>
              <ul className="rating">
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
              <p className="xs-margin-15px-bottom">
                “ Many desktop publishing packages and web page editors now use
                Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover. ”
              </p>
              <h5 className="margin-5px-bottom xs-no-margin-bottom font-size20 md-font-size18 xs-font-size16 font-weight-600 text-theme-color">
                Kelly Murphy
              </h5>
              <div className="font-size16 xs-font-size14">Customer</div>
            </div>

            <div className="client-block">
              <div className="icon">
                <i className="fa fa-quote-right"></i>
              </div>
              <div className="client-img">
                <img src={TestImg2} alt="" />
              </div>
              <ul className="rating">
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
              <p className="xs-margin-15px-bottom">
                “ It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum. ”
              </p>
              <h5 className="margin-5px-bottom xs-no-margin-bottom font-size20 md-font-size18 xs-font-size16 font-weight-600 text-theme-color">
                Sarah Katz
              </h5>
              <div className="font-size16 xs-font-size14">Director</div>
            </div>

            <div className="client-block">
              <div className="icon">
                <i className="fa fa-quote-right"></i>
              </div>
              <div className="client-img">
                <img src={TestImg3}alt="" />
              </div>
              <ul className="rating">
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li className="star-rate">
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
              <p className="xs-margin-15px-bottom">
                “ There are many variations of passages of Lorem Ipsum
                available, but the majority have suffered alteration in some
                form, by injected. ”
              </p>
              <h5 className="margin-5px-bottom xs-no-margin-bottom font-size20 md-font-size18 xs-font-size16 font-weight-600 text-theme-color">
                Randall Smith
              </h5>
              <div className="font-size16 xs-font-size14">Builder</div>
              
            </div>
           
          </div>
          
        </div>
     
      </section>
    </>
  );
};

export default Testimonial;
