import React from 'react'
import Header from '../Header/Header'
import Banner from '../Banner'
import banner1 from "../../asset/img/banner/page-title.jpg"
import { Link } from 'react-router-dom'



const DeskManage = () => {
  return (
   <>
  <Header/> 
  <Banner heading="Desk Management" name="Desk Management" img={banner1}/>

  <div className="container">
    <h1 className="mt-5">Desk Management System</h1>
    <div className="row mt-4">
      <div className="col-md-6">
        <div className="card desk-card">
          <div className="card-header bg-primary text-white">
            Book a Desk
          </div>
          <div className="card-body">
            <h5 className="card-title">Reserve your spot</h5>
            <p className="card-text">Book a desk in advance for your work.</p>
            <Link className="btn btn-primary">Book Desk</Link>
           
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card desk-card">
          <div className="card-header bg-success text-white">
            Check-in/Check-out
          </div>
          <div className="card-body">
            <h5 className="card-title">Mark your presence</h5>
            <p className="card-text">Check-in when you arrive and check-out when you leave.</p>
            <Link  className="btn btn-success">Check-in/Check-out</Link>
            
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-6">
        <div className="card desk-card">
          <div className="card-header bg-info text-white">
            Desk Availability
          </div>
          <div className="card-body">
            <h5 className="card-title">View availability</h5>
            <p className="card-text">Check the availability of desks for booking.</p>
            <Link  className="btn btn-info">Check Availability</Link>
          
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card desk-card">
          <div className="card-header bg-warning text-white">
            My Reservations
          </div>
          <div className="card-body">
            <h5 className="card-title">View your bookings</h5>
            <p className="card-text">View and manage your desk reservations.</p>
            <Link className="btn btn-warning">My Reservations</Link>
            
          </div>
        </div>
      </div>
    </div>
  </div>

   </>
  )
}

export default DeskManage