import React from "react";
import Banner from "../Banner";
import BannerImg from "../../asset/img/banner/page-title.jpg";
import ServiceImg1 from "../../asset/img/services/01.jpg";
import ServiceImg2 from "../../asset/img/services/02.jpg";
import ServiceImg3 from "../../asset/img/services/03.jpg";
import ServiceImg4 from "../../asset/img/services/04.jpg";
import { Link } from "react-router-dom";
import Header from "../Header/Header";


const ServiceDetailPage = () => {
  return (
    <>
    <Header/>
      <Banner img={BannerImg} heading="Service Detail" name="Service Detail" />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 sm-margin-40px-bottom">
              <div className="service-side-bar padding-40px-right md-padding-10px-right sm-no-padding-right">
                <div className="widget search">
                  <form method="post">
                    <input type="hidden" name="form-name" value="form 1" />
                    <input
                      type="search"
                      name="search"
                      placeholder="Type here ..."
                    />
                    <button type="submit" className="butn">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>

                <div className="widget">
                  <div className="widget-title">
                    <h6>Other Services</h6>
                  </div>
                  <ul className="no-margin-bottom">
                    <li>
                      <i className="ti-layout"></i>
                      <h5 className="no-margin-bottom font-size16 xs-font-size15 line-height-26 xs-line-height-24 font-weight-500">
                        <Link className="text-black"> Apartment Design</Link>
                      </h5>
                      <p className="no-margin-bottom">Page editors now use</p>
                    </li>
                    <li>
                      <i className="ti-write"></i>
                      <h5 className="no-margin-bottom font-size16 xs-font-size15 line-height-26 xs-line-height-24 font-weight-500">
                        <Link className="text-black"> House Painting</Link>
                      </h5>
                      <p className="no-margin-bottom">Randomised words which</p>
                    </li>
                    <li>
                      <i className="ti-eraser"></i>
                      <h5 className="no-margin-bottom font-size16 xs-font-size15 line-height-26 xs-line-height-24 font-weight-500">
                        <Link className="text-black"> Rennovation</Link>
                      </h5>
                      <p className="no-margin-bottom">
                        Many desktop publishing
                      </p>
                    </li>
                    <li>
                      <i className="ti-briefcase"></i>
                      <h5 className="no-margin-bottom font-size16 xs-font-size15 line-height-26 xs-line-height-24 font-weight-500">
                        <Link className="text-black"> Expert Mechanical</Link>
                      </h5>
                      <p className="no-margin-bottom">
                        Various versions evolved
                      </p>
                    </li>
                    <li>
                      <i className="ti-server"></i>
                      <h5 className="no-margin-bottom font-size16 xs-font-size15 line-height-26 xs-line-height-24 font-weight-500">
                        <Link className="text-black">Project Planning</Link>
                      </h5>
                      <p className="no-margin-bottom">
                        handful of model sentence
                      </p>
                    </li>
                    <li>
                      <i className="ti-agenda"></i>
                      <h5 className="no-margin-bottom font-size16 xs-font-size15 line-height-26 xs-line-height-24 font-weight-500">
                        <Link className="text-black">Concrete Supply</Link>
                      </h5>
                      <p className="no-margin-bottom">sometimes on purpose</p>
                    </li>
                  </ul>
                </div>

                <div className="widget">
                  <div className="widget-title">
                    <h6>Follow Us</h6>
                  </div>
                  <ul className="social-listing">
                    <li>
                      <Link>
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="banner-img-box">
                  <div className="z-index-9">
                    <h5 className="text-white font-weight-500 font-size32 md-font-size28 xs-font-size26 margin-30px-bottom xs-margin-20px-bottom">
                      Let’s Talk About Your Upcoming Project
                    </h5>
                    <Link className="butn small">Read More</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="service-detail-block">
                <div className="row image">
                  <div className="col-lg-8 padding-5px-lr sm-margin-10px-bottom xs-no-margin-bottom">
                    <div>
                      <img src={ServiceImg1} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-4 padding-5px-lr">
                    <div className="xs-display-none">
                      <img
                        src={ServiceImg2}
                        alt=""
                        className="margin-15px-bottom sm-no-margin-bottom"
                      />
                      <img src={ServiceImg3} alt="" />
                    </div>
                  </div>
                </div>

                <h4>Design and Build</h4>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here'.
                </p>
                <p className="no-margin-bottom">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words.
                </p>

                <blockquote>
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum' will uncover many web sites still in their
                  infancy. <div className="author">William Jomurray</div>
                </blockquote>

                <div className="row margin-50px-bottom sm-margin-30px-bottom">
                  <div className="col-lg-6">
                    <h6 className="md-margin-10px-bottom">
                      Buidling Renovation
                    </h6>
                    <p className="md-margin-10px-bottom">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley.
                    </p>
                    <ul className="list-styles no-margin-bottom">
                      <li>Majority have suffered alteration some.</li>
                      <li>Sure there isn't anything embarrassing hidden.</li>
                      <li>Lorem Ipsum is therefore always repetition.</li>
                      <li>Recently with desktop publishing.</li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <div className="sm-display-none">
                      <img src={ServiceImg4} alt="" />
                    </div>
                  </div>
                </div>

                <h4>Why Choose Us?</h4>
                <p className="margin-30px-bottom sm-margin-25px-bottom">
                  It uses a dictionary of over 200 Latin words, combined with a
                  handful of model sentence structures, to generate Lorem Ipsum
                  which looks reasonable. The generated Lorem Ipsum is therefore
                  always free from repetition.
                </p>
                <div className="row">
                  <div className="col-md-3 col-sm-6 col-6 xs-margin-20px-bottom">
                    <div className="text-center">
                      <i className="ti-user text-theme-color font-size26 xs-font-size24"></i>
                      <h6 className="no-margin-bottom font-weight-500 font-size16 md-font-size14 margin-10px-top xs-margin-5px-top">
                        Dedicated teams
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6 xs-margin-20px-bottom">
                    <div className="text-center">
                      <i className="ti-thumb-up text-theme-color font-size26 xs-font-size24"></i>
                      <h6 className="no-margin-bottom font-weight-500 font-size16 md-font-size14 margin-10px-top xs-margin-5px-top">
                        True partners
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6">
                    <div className="text-center">
                      <i className="ti-world text-theme-color font-size26 xs-font-size24"></i>
                      <h6 className="no-margin-bottom font-weight-500 font-size16 md-font-size14 margin-10px-top xs-margin-5px-top">
                        Planning Strategies
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 col-6">
                    <div className="text-center">
                      <i className="ti-target text-theme-color font-size26 xs-font-size24"></i>
                      <h6 className="no-margin-bottom font-weight-500 font-size16 md-font-size14 margin-10px-top xs-margin-5px-top">
                        Financial Analysis
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetailPage;
