import React from "react";
import HistoryImg from "../../asset/img/history/history-1.jpg";

const Story = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row flex-row">
            <div className="col-md-12">
              <h3 className="font-size14 text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">
                Our Story
              </h3>
              <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500">
                Construction 1994-2019
              </h4>
              <p className="margin-50px-bottom mobile-margin-40px-bottom">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum. Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium doloremque laudantium, totam rem aperiam,
                eaque ipsa.
              </p>
            </div>
            <div className="col-md-12">
              <div className="history position-relative">
                <div className="row flex-row">
                  <div className="col-lg-2 col-xs-12 align-self-center">
                    <h3 className="text-theme-color text-center no-margin-bottom">
                      2019 -
                    </h3>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="history-line"></div>
                    <div className="history-img">
                      <img
                        src={HistoryImg}
                        alt=""
                        className="padding-30px-tb md-padding-25px-tb xs-padding-20px-tb"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12 align-self-center">
                    <h4 className="font-weight-500 font-size26 md-font-size24 xs-font-size22 md-margin-10px-bottom">
                      The company growing
                    </h4>
                    <p className="no-margin-bottom">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto.
                    </p>
                  </div>
                </div>
              </div>
              <div className="history">
                <div className="row flex-row">
                  <div className="col-lg-2 col-xs-12 align-self-center">
                    <h3 className="text-theme-color text-center no-margin-bottom">
                      2017 -
                    </h3>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="history-line"></div>
                    <div className="history-img">
                      <img
                       src={HistoryImg}
                        alt=""
                        className="padding-30px-tb md-padding-25px-tb xs-padding-20px-tb"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12 align-self-center">
                    <h4 className="font-weight-500 font-size26 md-font-size24 xs-font-size22 md-margin-10px-bottom">
                      The company eminence
                    </h4>
                    <p className="no-margin-bottom">
                      Imagni dolores eos qui ratione voluptatem sequi nesciunt.
                      Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                      amet, consectetur, adipisci velit, sed quia non numquam
                      eius modi tempora incidunt ut labore et dolore magnam
                      aliquam quaerat voluptatem.
                    </p>
                  </div>
                </div>
              </div>
              <div className="history">
                <div className="row flex-row">
                  <div className="col-lg-2 col-xs-12 align-self-center">
                    <h3 className="text-theme-color text-center no-margin-bottom">
                      2016 -
                    </h3>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="history-line"></div>
                    <div className="history-img">
                      <img
                       src={HistoryImg}
                        alt=""
                        className="padding-30px-tb md-padding-25px-tb xs-padding-20px-tb"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12 align-self-center">
                    <h4 className="font-weight-500 font-size26 md-font-size24 xs-font-size22 md-margin-10px-bottom">
                      The company success
                    </h4>
                    <p className="no-margin-bottom">
                      Commodo consequat. Duis aute irure dolor in reprehenderit
                      in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident,
                      sunt in culpa qui officia deserunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="history">
                <div className="row flex-row">
                  <div className="col-lg-2 col-xs-12 align-self-center">
                    <h3 className="text-theme-color text-center no-margin-bottom">
                      2013 -
                    </h3>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="history-line"></div>
                    <div className="history-img">
                      <img
                       src={HistoryImg}
                        alt=""
                        className="padding-30px-tb md-padding-25px-tb xs-padding-20px-tb"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12 align-self-center">
                    <h4 className="font-weight-500 font-size26 md-font-size24 xs-font-size22 md-margin-10px-bottom">
                      The company recognition
                    </h4>
                    <p className="no-margin-bottom">
                      Wotam rem aperiam, eaque ipsa quae ab illo inventore
                      veritatis et quasi architecto beatae vitae dicta sunt
                      explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                      aspernatur aut odit aut fugit, sed quia nesciunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="history">
                <div className="row flex-row">
                  <div className="col-lg-2 col-xs-12 align-self-center">
                    <h3 className="text-theme-color text-center no-margin-bottom">
                      2010 -
                    </h3>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="history-line"></div>
                    <div className="history-img">
                      <img
                       src={HistoryImg}
                        alt=""
                        className="padding-30px-tb md-padding-25px-tb xs-padding-20px-tb"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12 align-self-center">
                    <h4 className="font-weight-500 font-size26 md-font-size24 xs-font-size22 md-margin-10px-bottom">
                      The company ascent
                    </h4>
                    <p className="no-margin-bottom">
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="history">
                <div className="row flex-row">
                  <div className="col-lg-2 col-xs-12 align-self-center">
                    <h3 className="text-theme-color text-center no-margin-bottom">
                      1993 -
                    </h3>
                  </div>
                  <div className="col-lg-4 col-xs-12">
                    <div className="history-line"></div>
                    <div className="history-img">
                      <img
                     src={HistoryImg}
                        alt=""
                        className="padding-30px-top md-padding-25px-top sm-padding-25px-tb xs-padding-20px-tb"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12 align-self-center">
                    <h4 className="font-weight-500 font-size26 md-font-size24 xs-font-size22 md-margin-10px-bottom">
                      The company inception
                    </h4>
                    <p className="no-margin-bottom">
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum. Sed
                      ut perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Story;
