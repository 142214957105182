import React from 'react'
import PartImg1 from "../../asset/img/partners/client-01.png"

const Partnership = () => {
  return (
<>
<section>
            <div className="container" style={{marginTop:"-1px"}}>
                <div className="margin-50px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center">
                    <h3 className="font-size13 text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">Partnerships & Affiliations</h3>
                    <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">Premium Partnership</h4>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-7 col-sm-7 col-8 sm-center-col margin-30px-bottom sm-margin-20px-bottom">
                        <div className="item border padding-40px-all md-padding-35px-tb md-padding-20px-lr xs-padding-20px-all text-center">
                            <img alt="partner" src={PartImg1} className="width-180px opacity7"/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-7 col-sm-7 col-8 sm-center-col margin-30px-bottom sm-margin-20px-bottom">
                        <div className="item border padding-40px-all md-padding-35px-tb md-padding-20px-lr xs-padding-20px-all text-center">
                            <img alt="partner" src={PartImg1} className="width-180px opacity7"/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-7 col-sm-7 col-8 sm-center-col margin-30px-bottom sm-margin-20px-bottom">
                        <div className="item border padding-40px-all md-padding-35px-tb md-padding-20px-lr xs-padding-20px-all text-center">
                            <img alt="partner" src={PartImg1} className="width-180px opacity7"/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-7 col-sm-7 col-8 sm-center-col margin-30px-bottom sm-margin-20px-bottom">
                        <div className="item border padding-40px-all md-padding-35px-tb md-padding-20px-lr xs-padding-20px-all text-center">
                            <img alt="partner" src={PartImg1} className="width-180px opacity7"/>
                        </div>
                    </div>
                 
                </div>
            </div>
        </section>
</>
  )
}

export default Partnership