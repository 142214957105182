import React from "react";
import Banner from "../Banner";
import BannerImg from "../../asset/img/banner/page-title.jpg";
import { Link } from "react-router-dom";
import Header from "../Header/Header";

const TeamPage = () => {
  return (
    <>
    <Header/>
      <Banner img={BannerImg} heading="Team" name="Team" />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 margin-30px-bottom mobile-margin-20px-bottom">
              <div className="team-block position-relative">
                <div className="team-img position-relative overflow-hidden">
                  <img src="img/team/01.jpg" alt="" />
                  <div className="box-overlay"></div>
                  <ul className="social-icons no-margin-bottom">
                    <li>
                      <Link>
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail bg-light-gray padding-30px-all md-padding-25px-all xs-padding-15px-all text-center">
                  <h6 className="margin-5px-bottom xs-no-margin-bottom font-size20 xs-font-size18">
                    <Link className="text-black">Michael Thompson</Link>
                  </h6>
                  <p className="no-margin-bottom">Installation Engineer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 margin-30px-bottom mobile-margin-20px-bottom">
              <div className="team-block position-relative">
                <div className="team-img position-relative overflow-hidden">
                  <img src="img/team/02.jpg" alt="" />
                  <div className="box-overlay"></div>
                  <ul className="social-icons no-margin-bottom">
                    <li>
                      <Link>
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail bg-light-gray padding-30px-all md-padding-25px-all xs-padding-15px-all text-center">
                  <h6 className="margin-5px-bottom xs-no-margin-bottom font-size20 xs-font-size18">
                    <Link className="text-black">Carmen Robinson</Link>
                  </h6>
                  <p className="no-margin-bottom">Installation Engineer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 margin-30px-bottom mobile-margin-20px-bottom">
              <div className="team-block position-relative">
                <div className="team-img position-relative overflow-hidden">
                  <img src="img/team/03.jpg" alt="" />
                  <div className="box-overlay"></div>
                  <ul className="social-icons no-margin-bottom">
                    <li>
                      <Link>
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail bg-light-gray padding-30px-all md-padding-25px-all xs-padding-15px-all text-center">
                  <h6 className="margin-5px-bottom xs-no-margin-bottom font-size20 xs-font-size18">
                    <Link className="text-black">Carl Smith</Link>
                  </h6>
                  <p className="no-margin-bottom">Installation Engineer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 sm-margin-30px-bottom mobile-margin-20px-bottom">
              <div className="team-block position-relative">
                <div className="team-img position-relative overflow-hidden">
                  <img src="img/team/04.jpg" alt="" />
                  <div className="box-overlay"></div>
                  <ul className="social-icons no-margin-bottom">
                    <li>
                      <Link>
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail bg-light-gray padding-30px-all md-padding-25px-all xs-padding-15px-all text-center">
                  <h6 className="margin-5px-bottom xs-no-margin-bottom font-size20 xs-font-size18">
                    <Link className="text-black">Mira Hazel</Link>
                  </h6>
                  <p className="no-margin-bottom">Installation Engineer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 mobile-margin-20px-bottom">
              <div className="team-block position-relative">
                <div className="team-img position-relative overflow-hidden">
                  <img src="img/team/05.jpg" alt="" />
                  <div className="box-overlay"></div>
                  <ul className="social-icons no-margin-bottom">
                    <li>
                      <Link>
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail bg-light-gray padding-30px-all md-padding-25px-all xs-padding-15px-all text-center">
                  <h6 className="margin-5px-bottom xs-no-margin-bottom font-size20 xs-font-size18">
                    <Link className="text-black">Emanuel Galles</Link>
                  </h6>
                  <p className="no-margin-bottom">Installation Engineer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="team-block position-relative">
                <div className="team-img position-relative overflow-hidden">
                  <img src="img/team/06.jpg" alt="" />
                  <div className="box-overlay"></div>
                  <ul className="social-icons no-margin-bottom">
                    <li>
                      <Link>
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-twitter-alt"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="detail bg-light-gray padding-30px-all md-padding-25px-all xs-padding-15px-all text-center">
                  <h6 className="margin-5px-bottom xs-no-margin-bottom font-size20 xs-font-size18">
                    <Link className="text-black"> Tony Martinez</Link>
                  </h6>
                  <p className="no-margin-bottom">Installation Engineer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamPage;
