import React from "react";
import Header from "../Header/Header";
import Banner from "../Banner";
import BannerImg from "../../asset/img/banner/page-title.jpg";
import Img from "../../asset/img/portfolio/01.jpg";
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <>
      <Header />
      <Banner img={BannerImg} heading="Career" name="Career" />
      <section className="no-padding-top" style={{ marginTop: "10px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-content-section">
                <img src={Img} alt="" className="img-fluid" />
                <div className="content">
                  <h3>Join Our Team</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Sed
                  </p>
                  <Link className="btn btn-secondary">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="padding-40px-all sm-padding-30px-all xs-padding-20px-all " style={{backgroundColor:"#b8edcb",color:"white"}}>
                <h4 className="font-size38 sm-font-size34 xs-font-size28 line-height-45 sm-line-height-40 font-weight-500 margin-10px-bottom text-center">
                  Career
                </h4>
                <p
                  className="margin-50px-bottom sm-margin-30px-bottom xs-margin-25px-bottom width-60 md-width-70 sm-width-auto center-col text-center"
                  style={{ color: "red" }}
                >
                  Lorem ipsum dolor sit ame
                </p>
                <form
                  action="email.php"
                  method="post"
                  id="career-form"
                  encType="multipart/form-data"
                >
                  <div
                    className="form-group"
                    style={{ marginBottom: "0px", marginTop: "-11px" }}
                  >
                    <label>Name</label>
                    <input
                      name="name"
                      placeholder="Enter your name"
                      type="text"
                      className="form-control"
                      required
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{ marginBottom: "0px", marginTop: "-11px" }}
                  >
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      className="form-control"
                      required
                    />
                  </div>
                  <div
                    className="form-group"
                    style={{ marginBottom: "0px", marginTop: "-11px" }}
                  >
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      name="number"
                      placeholder="Enter your number"
                      className="form-control"
                      required
                    />
                  </div>

                  <div
                    className="form-group"
                    style={{ marginBottom: "0px", marginTop: "-11px" }}
                  >
                    <label>Position</label>
                    <select
                      name="subject_applied"
                      className="form-control"
                      required
                    >
                      <option value="">Select Job</option>
                      <option value="Job1">Job 1</option>
                      <option value="Job2">Job 2</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{ marginBottom: "0px", marginTop: "-11px" }}
                  >
                    <label>Upload Your Resume</label>
                    <input
                      type="file"
                      name="resume"
                      className="form-control-file"
                      accept=".pdf,.doc,.docx"
                      required
                    />
                  </div>

                  <div className="text-center">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
                <p className="form-message"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
