import React from "react";
import ContentImg from "../../asset/img/content/about-3.jpg";

const AboutService = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 sm-margin-30px-bottom">
              <div className="about-block">
                <div className="image">
                  <img src={ContentImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="padding-80px-left md-padding-50px-left sm-no-padding-left">
                <h3 className="font-size15 md-font-size14 sm-font-size13 text-uppercase margin-15px-bottom md-margin-10px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">
                  Subhasavilla
                </h3>
                <h4 className="font-size40 md-font-size36 sm-font-size30 xs-font-size26 font-weight-500 margin-25px-bottom md-margin-25px-bottom sm-margin-15px-bottom">
                  Let’s Get To Know About Subhasavilla
                </h4>
                <p>
                  At XYZ Company, we are dedicated to providing innovative
                  solutions to meet the diverse needs of our clients. With a
                  focus on excellence and customer satisfaction, we strive to
                  deliver top-quality products and services that exceed
                  expectations.
                </p>
                <h5 className="font-size20 margin-10px-bottom font-weight-500">
                  Our Mission
                </h5>
                <p>
                  Our mission is to empower businesses and individuals through
                  cutting-edge technology and superior services. We aim to be a
                  trusted partner for our clients, helping them achieve their
                  goals and succeed in today's competitive market.
                </p>
                <h5 className="font-size20 margin-10px-bottom font-weight-500">
                  Our Vision 
                </h5>
                <p className="no-margin-bottom">
                  Many desktop publishing packages and web page editors now use
                  Lorem Ipsum as their default model text, and a search for
                  'lorem ipsum' will uncover many web sites still in their
                  infancy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutService;
