import React from "react";

import { Link } from "react-router-dom";

export const Prodcut = () => {
  return (
    <>
      <h4
        className="font-size38 sm-font-size34 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom"
        style={{ textAlign: "center" }}
      >
        Our Product
      </h4>
      <div className="container">
        {/* <h1
          className="font-size38 sm-font-size34 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom"
          style={{ marginTop: "30px" }}
        >
          Ticket Management System
        </h1> */}
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="card ticket-card">
              <div className="card-header bg-primary text-white">
                Ticket Management System
              </div>
              <div className="card-body">
                <h5 className="card-title">Fill out the form</h5>
                <p className="card-text">
                  Submit a new ticket with the required details.
                </p>
                <Link className="btn btn-primary">Create Ticket</Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card desk-card">
              <div className="card-header bg-primary text-white">
                Desk Management System
              </div>
              <div className="card-body">
                <h5 className="card-title">Reserve your spot</h5>
                <p className="card-text">
                  Book a desk in advance for your work.
                </p>
                <Link className="btn btn-primary">Book Desk</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="card ticket-card">
              <div className="card-header bg-info text-white">
                Support Center
              </div>
              <div className="card-body">
                <h5 className="card-title">Get Help</h5>
                <p className="card-text">
                  Connect with our support team for assistance.
                </p>
                <Link className="btn btn-info">Contact Support</Link>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="card ticket-card">
              <div className="card-header bg-warning text-white">
                Knowledge Base
              </div>
              <div className="card-body">
                <h5 className="card-title">Explore</h5>
                <p className="card-text">
                  Access our knowledge base for helpful resources and guides.
                </p>
                <Link className="btn btn-warning">Visit Knowledge Base</Link>
              </div>
            </div>
          </div> */}
          <div className="col-md-6">
            <div className="card desk-card">
              <div className="card-header bg-warning text-white">
                My Reservations
              </div>
              <div className="card-body">
                <h5 className="card-title">View your bookings</h5>
                <p className="card-text">
                  View and manage your desk reservations.
                </p>
                <Link className="btn btn-warning">My Reservations</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <h1 className="font-size38 sm-font-size34 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
          Desk Management System
        </h1>
        <div className="row mt-4">
           <div className="col-md-6">
            <div className="card desk-card">
              <div className="card-header bg-primary text-white">
                Book a Desk
              </div>
              <div className="card-body">
                <h5 className="card-title">Reserve your spot</h5>
                <p className="card-text">
                  Book a desk in advance for your work.
                </p>
                <Link className="btn btn-primary">Book Desk</Link>
              </div>
            </div>
          </div> 
          <div className="col-md-6">
            <div className="card desk-card">
              <div className="card-header bg-success text-white">
                Check-in/Check-out
              </div>
              <div className="card-body">
                <h5 className="card-title">Mark your presence</h5>
                <p className="card-text">
                  Check-in when you arrive and check-out when you leave.
                </p>
                <Link className="btn btn-success">Check-in/Check-out</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="card desk-card">
              <div className="card-header bg-info text-white">
                Desk Availability
              </div>
              <div className="card-body">
                <h5 className="card-title">View availability</h5>
                <p className="card-text">
                  Check the availability of desks for booking.
                </p>
                <Link className="btn btn-info">Check Availability</Link>
              </div>
            </div>
          </div>
           <div className="col-md-6">
            <div className="card desk-card">
              <div className="card-header bg-warning text-white">
                My Reservations
              </div>
              <div className="card-body">
                <h5 className="card-title">View your bookings</h5>
                <p className="card-text">
                  View and manage your desk reservations.
                </p>
                <Link className="btn btn-warning">My Reservations</Link>
              </div>
            </div>
        </div> 
        </div>
      </div> */}
    </>
  );
};
