import React from 'react'
import Header from '../Header/Header'
import { Link } from 'react-router-dom'
import Banner from '../Banner'
import banner1 from "../../asset/img/banner/page-title.jpg"



const TicketManagement = () => {
  return (
    <>
   <Header/>
   <Banner heading="Ticket Management" name="Ticket Management" img={banner1}/>
   <div className="container">
    <h1 className="mt-5">Ticket Management System</h1>
    <div className="row mt-4">
      <div className="col-md-6">
        <div className="card ticket-card">
          <div className="card-header bg-primary text-white">
            Create New Ticket
          </div>
          <div className="card-body">
            <h5 className="card-title">Fill out the form</h5>
            <p className="card-text">Submit a new ticket with the required details.</p>
            <Link className="btn btn-primary">Create Ticket</Link>
            
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card ticket-card">
          <div className="card-header bg-success text-white">
            Track Existing Tickets
          </div>
          <div className="card-body">
            <h5 className="card-title">Check status</h5>
            <p className="card-text">View the status and progress of your existing tickets.</p>
            <Link className="btn btn-success">Track Tickets</Link>
           
          </div>
        </div>
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-md-6">
        <div className="card ticket-card">
          <div className="card-header bg-info text-white">
            Support Center
          </div>
          <div className="card-body">
            <h5 className="card-title">Get Help</h5>
            <p className="card-text">Connect with our support team for assistance.</p>
            <Link className="btn btn-info">Contact Support</Link>
            
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card ticket-card">
          <div className="card-header bg-warning text-white">
            Knowledge Base
          </div>
          <div className="card-body">
            <h5 className="card-title">Explore</h5>
            <p className="card-text">Access our knowledge base for helpful resources and guides.</p>
            <Link  className="btn btn-warning">Visit Knowledge Base</Link>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}

export default TicketManagement