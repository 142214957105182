import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Footer from "./components/Footer/Footer";
import Contactus from "./components/pages/Contactus";
import "../src/asset/css/styles.css";
import "../src/asset/css/plugins.css";
import "../src/asset/search/search.css";
import AboutPage from "./components/pages/AboutPage";
import ServicePage from "./components/pages/ServicePage";
import AwardPage from "./components/pages/AwardPage";
import FaqPage from "./components/pages/FaqPage";
import TeamPage from "./components/pages/TeamPage";
import ProjectDetailsPage from "./components/pages/ProjectDetailsPage";
import BlogGrid from "./components/pages/BlogGrid";
import Blogdefault from "./components/pages/Blogdefault";
import BlogPost from "./components/pages/BlogPost";
import HistoryPage from "./components/pages/HistoryPage";
import ServiceDetailPage from "./components/pages/ServiceDetailPage";
import TicketManagement from "./components/pages/TicketManagement";
import DeskManage from "./components/pages/DeskManage";
import Galler from "./components/pages/Galler";
import Service1 from "./components/pages/Service1";
import Career from "./components/pages/Career";

function App() {
  return (
    <>
      <div className="main-wrapper">
        <Router>
          <Routes>
            <Route path="/" element={<Layout />} />
            <Route path="/contact" element={<Contactus />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/award" element={<AwardPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/projectdetails" element={<ProjectDetailsPage />} />
            <Route path="/bloggrid" element={<BlogGrid />} />
            <Route path="/blogdefault" element={<Blogdefault />} />
            <Route path="/blogpost" element={<BlogPost />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/servicedetails" element={<ServiceDetailPage />} />
            <Route path="/ticket" element={<TicketManagement />} />
            <Route path="/desk" element={<DeskManage />} />
            <Route path="/gallery" element={<Galler />} />
            <Route path="/service1" element={<Service1 />} />
            <Route path="/career" element={<Career />} />
          </Routes>
          <Footer />
          
        </Router>
      </div>
    </>
  );
}

export default App;
