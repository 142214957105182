import React from "react";
import Bg1 from "../../asset/img/banner/page-title.jpg"
import Cert1 from "../../asset/img/certification/01.jpg"
import Cert2 from "../../asset/img/certification/02.jpg"
import Cert3 from "../../asset/img/certification/03.jpg"
import Cert4 from "../../asset/img/certification/04.jpg"
import Cert5 from "../../asset/img/certification/05.jpg"
import Cert6 from "../../asset/img/certification/06.jpg"
import Cert7 from "../../asset/img/certification/07.jpg"
import Cert8 from "../../asset/img/certification/08.jpg"
import Cert9 from "../../asset/img/certification/09.jpg"
import Banner from "../Banner";



const AwardPage = () => {
  return (
    <>
      <Banner img={Bg1} heading="Certification" name="Certification"/>

      <section>
        <div className="container">
          <div className="margin-50px-bottom xs-margin-40px-bottom text-center">
            <h3 className="font-size13 text-uppercase margin-15px-bottom xs-margin-5px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">
              DO YOU KNOWS US?
            </h3>
            <h4 className="font-size38 sm-font-size32 xs-font-size28 line-height-45 sm-line-height-40 font-weight-500 no-margin-bottom width-70 xs-width-auto center-col">
              An International Awards Winning Construction Company
            </h4>
          </div>
          <div className="row">
            <div className="col-lg-10 center-col">
              <div className="row">
                <div className="gallery text-center width-100">
                  <div className="col-lg-4 margin-30px-bottom items">
                    <a href={Cert1} className="popimg">
                      <img src={Cert1} alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 margin-30px-bottom items">
                    <a href="img/certification/02.jpg" className="popimg">
                      <img src={Cert2} alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 margin-30px-bottom items">
                    <a href="img/certification/03.jpg" className="popimg">
                      <img src={Cert3} alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 margin-30px-bottom items">
                    <a href="img/certification/04.jpg" className="popimg">
                <img src={Cert4}alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 margin-30px-bottom items">
                    <a href="img/certification/05.jpg" className="popimg">
                      <img src={Cert5} alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 margin-30px-bottom items">
                    <a href="img/certification/06.jpg" className="popimg">
                      <img src={Cert6}alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 sm-margin-30px-bottom items">
                    <a href="img/certification/07.jpg" className="popimg">
                      <img src={Cert7} alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 sm-margin-30px-bottom items">
                    <a href="img/certification/08.jpg" className="popimg">
                      <img src={Cert8} alt="" />
                    </a>
                  </div>
                  <div className="col-lg-4 items">
                    <a href="img/certification/09.jpg" className="popimg">
                      <img src={Cert9} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AwardPage;
