import React from 'react'


const Video = () => {
  return (
    <>
   <section class="gallery-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
        
          <div class="embed-responsive embed-responsive-16by9">
            <video class="embed-responsive-item" controls>
              <source src="your-video.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="col-md-6">
       
          <audio controls>
            <source src="your-audio.mp3" type="audio/mpeg"/>
            Your browser does not support the audio element.
          </audio>
       
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="image1.jpg" class="d-block w-100" alt=""/>
              </div>
              <div class="carousel-item">
                <img src="image2.jpg" class="d-block w-100" alt=""/>
              </div>
            
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

    </>
  )
}

export default Video