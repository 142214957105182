import React from 'react'
import Image from './Image'
import Header from '../Header/Header'
import Banner from '../Banner'
import banner1 from "../../asset/img/banner/page-title.jpg"
import Video from './Video'


const Galler = () => {
  return (
    <>
    
    <Header/>
    <Banner img={banner1} heading="Gallery" name="Gallery"/>
   <Image/>
   <Video/>
   </>
  )
}

export default Galler