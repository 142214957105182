import React from "react";
import Img1 from "../../asset/img/content/project-details.jpg";
import Img2 from "../../asset/img/content/project-details2.jpg";
import BannerImg from "../../asset/img/banner/page-title.jpg";
import Banner from '../Banner'
import Header from "../Header/Header";





const ProjectDetailsPage = () => {
  return (
    <>
    <Header/>
    <Banner img={BannerImg} heading="Project Details " name="Project Details"/>

      <section>
        <div className="container">
          <div className="project-detail-block">
            <div className="row margin-40px-bottom md-margin-25px-bottom">
              <div className="col-lg-8 sm-margin-25px-bottom">
                <div className="">
                  <img src={Img1} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="padding-20px-left md-no-padding-left">
                  <h5> Trust Our Koncrete</h5>
                  <p className="md-display-none sm-display-block">
                    Lorem ipsum dolor sit amet, consectetur adipimagna aliqua.
                    Ut enims ad minim veniam. Aenean mus et magnis dis partu
                    rient to montes.Aene an massa.
                  </p>
                  <ul className="project-list no-margin-bottom">
                    <li>
                      <i className="ti-briefcase"></i>
                      <h6 className="no-margin-bottom font-size16 xs-font-size15 line-height-22 font-weight-500">
                        Project Title
                      </h6>
                      <p className="no-margin-bottom">Startup Project</p>
                    </li>
                    <li>
                      <i className="ti-user"></i>
                      <h6 className="no-margin-bottom font-size16 xs-font-size15 line-height-22 font-weight-500">
                        Client
                      </h6>
                      <p className="no-margin-bottom">Sminth Evado</p>
                    </li>
                    <li>
                      <i className="ti-bookmark"></i>
                      <h6 className="no-margin-bottom font-size16 xs-font-size15 line-height-22 font-weight-500">
                        Category
                      </h6>
                      <p className="no-margin-bottom">Construction</p>
                    </li>
                    <li>
                      <i className="ti-location-pin"></i>
                      <h6 className="no-margin-bottom font-size16 xs-font-size15 line-height-22 font-weight-500">
                        Location
                      </h6>
                      <p className="no-margin-bottom">
                        27 Division St, New York, USA
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <h4>Find out Why Patients Trust Our Koncrete</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="margin-40px-bottom md-margin-25px-bottom">
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, sed quia consequuntur magni dolores eos qui ratione
              voluptatem sequi nesciunt.
            </p>
            <h6 className="font-weight-500">Project Challenge</h6>
            <div className="row">
              <div className="col-lg-6">
                <ul className="project-list2 no-margin-bottom">
                  <li>Aabore et dolore magnam aliquam quaerat voluptatem.</li>
                  <li>
                    Consectetur, adipisci velit, sed quia non numquam eius
                  </li>
                  <li>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="project-list2 no-margin-bottom">
                  <li>Ut enim ad minim veniam, quis nostrud </li>
                  <li>Excepteur sint occaecat cupidatat non proident</li>
                  <li>Sed ut perspiciatis unde omnis iste natus error sit </li>
                </ul>
              </div>
            </div>
            <div className="row margin-50px-top md-margin-25px-top xs-margin-20px-top align-items-center">
              <div className="col-lg-6">
                <h6 className="font-weight-500">What We Did</h6>
                <p>
                  ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.{" "}
                </p>
                <h6 className="font-weight-500">Result</h6>
                <p className="no-margin-bottom">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur autest.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="sm-display-none">
                  <img src={Img2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectDetailsPage;
