import React from 'react'
import Blog from '../Layout/Blog'

const Image = () => {
  return (
    <>
    

    <Blog/>
    </>
  )
}

export default Image