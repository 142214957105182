import React from "react";
import BannerImg from "../../asset/img/banner/page-title.jpg";
import AboutService from "../Layout/AboutService";
import Testimonial from "../Layout/Testimonial";
import Banner from "../Banner";
import Header from "../Header/Header";
import TeamImg1 from "../../asset/img/team/02.jpg";
import TeamCard from "../TeamCard";
// import Cert1 from "../../asset/img/certification/01.jpg";
// import Cert2 from "../../asset/img/certification/02.jpg";
// import Cert3 from "../../asset/img/certification/03.jpg";
// import Cert4 from "../../asset/img/certification/04.jpg";
// import Cert5 from "../../asset/img/certification/05.jpg";
// import Cert6 from "../../asset/img/certification/06.jpg";
// import Cert7 from "../../asset/img/certification/07.jpg";
// import Cert8 from "../../asset/img/certification/08.jpg";
// import Cert9 from "../../asset/img/certification/09.jpg";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>
      {/* 
  <Nav nav="navbar-default border-bottom border-color-light-white"
   
  search="top-search bg-theme border-top"/> */}
      <Header />
      <Banner img={BannerImg} heading="About us" name="About us" />
      <AboutService />
      <h3 style={{ textAlign: "center" ,marginTop:"-10px",marginBottom:"-20px" }}>Our Team</h3>
      <TeamCard  />

      {/* Founder message  */}
      <section class="founder-message">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <img src={TeamImg1} alt="Founder" class="img-fluid" />
            </div>
            <div class="col-md-6">
              <h2>Founder's Message</h2>
              <h3 style={{ color: "violet" }}>John Doe</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                vitae mi metus. Proin accumsan augue a odio sodales, vel commodo
                justo interdum. Suspendisse potenti.
              </p>
              <p>
                Nullam nec dui nec tortor volutpat mollis. Sed vel lacus a leo
                consequat vestibulum nec a quam. Integer nec posuere ex. Vivamus
                id lorem vel est luctus elementum.
              </p>
              <Link class="btn btn-primary">Learn More</Link>
            </div>
          </div>
        </div>
      </section>
      {/* Company Policy */}
      <h3 style={{ textAlign: "center", marginTop:"20px"}}>
        Our Policy
      </h3>
      <section class="company-policy">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="policy-item">
                <h3>Privacy Policy</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  vitae mi metus. Proin accumsan augue a odio sodales, vel
                  commodo justo interdum. Suspendisse potenti.
                </p>
                <Link class="btn btn-primary">Read More</Link>
              </div>
              <div class="policy-item">
                <h3>Code of Conduct</h3>
                <p>
                  Nullam nec dui nec tortor volutpat mollis. Sed vel lacus a leo
                  consequat vestibulum nec a quam. Integer nec posuere ex.
                  Vivamus id lorem vel est luctus elementum.
                </p>
                <Link class="btn btn-primary">Read More</Link>
              </div>
            </div>
            <div class="col-md-6">
              <div class="policy-item">
                <h3>Quality Policy</h3>
                <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
                <Link class="btn btn-primary">Read More</Link>
              </div>
              <div class="policy-item">
                <h3>Environmental Policy</h3>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <Link class="btn btn-primary">Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <CounterCard /> */}
      {/* <section
        className="parallax"
        data-overlay-dark="75"
        data-background="img/content/extra-bg.jpg"
      >
        <div className="container">
          <div className="text-center">
            <h4 className="text-white font-weight-500 font-size50 md-font-size44 sm-font-size40 xs-font-size34">
              Find Out Why <br /> Than{" "}
              <span className="text-theme-color"> 490,000 </span> Construction
              Work{" "}
            </h4>
            <p className="text-white width-50 md-width-60 sm-width-80 xs-width-auto center-col font-size16 margin-30px-bottom">
              Trust Construction Work.
            </p>
            <Link className="butn white text-capitalize" to="/contact">
              <span>Contact</span>
            </Link>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="padding-40px-right md-padding-30px-right sm-no-padding-right">
                <h3 className="font-size15 md-font-size14 sm-font-size13 text-uppercase margin-15px-bottom md-margin-10px-bottom font-weight-400 letter-spacing-3 word-spacing-3 text-theme-color">
                  Construction Work
                </h3>
                <h4 className="font-size40 md-font-size36 sm-font-size30 xs-font-size26 font-weight-500 margin-40px-bottom md-margin-25px-bottom">
                  We Provide Best Construction Material
                </h4>

                <div className="row service-blocks">
                  <div className="col-lg-2 col-sm-1 col-2">
                    <div className="numbers">
                      <span>01</span>
                    </div>
                  </div>
                  <div className="col-lg-10 col-sm-11 col-10">
                    <div className="sm-padding-15px-left xs-padding-10px-left mobile-no-padding-left">
                      <h5 className="font-size18 sm-font-size16 margin-5px-bottom">
                        House Work
                      </h5>
                      <p className="no-margin-bottom">
                        Model sentence structures, to generate Lorem Ipsum which
                        looks reasonable.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row service-blocks">
                  <div className="col-lg-2 col-sm-1 col-2">
                    <div className="numbers">
                      <span>02</span>
                    </div>
                  </div>
                  <div className="col-lg-10 col-sm-11 col-10">
                    <div className="sm-padding-15px-left xs-padding-10px-left mobile-no-padding-left">
                      <h5 className="font-size18 margin-5px-bottom md-margin-5px-bottom xs-margin-10px-bottom md-font-size18 sm-font-size16">
                        Building Work
                      </h5>
                      <p className="no-margin-bottom">
                        It is a long established fact that a reader will be
                        distracted by the readable.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 sm-margin-30px-bottom">
              <div className="padding-40px-left md-padding-20px-left sm-no-padding-left text-center">
                <img src="img/content/about-5.jpg" alt="hi" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div class="container">
        <h1 class="text-center my-4">Awards & Achievement</h1>

        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="card">
              <img src="award1.jpg" class="card-img-top" alt="Award 1" />
              <div class="card-body">
                <h5 class="card-title">Award Title 1</h5>
                <p class="card-text">
                  Description of the award or achievement.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="card">
              <img src="award2.jpg" class="card-img-top" alt="Award 2" />
              <div class="card-body">
                <h5 class="card-title">Award Title 2</h5>
                <p class="card-text">
                  Description of the award or achievement.
                </p>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-center my-4">Certifications</h2>
        <div class="row">
          <div class="col-md-4 mb-3">
            <div class="card">
              <img
                src="certification1.jpg"
                class="card-img-top"
                alt="Certification 1"
              />
              <div class="card-body">
                <h5 class="card-title">Certification Title 1</h5>
                <p class="card-text">Description of the certification.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="card">
              <img
                src="certification2.jpg"
                class="card-img-top"
                alt="Certification 2"
              />
              <div class="card-body">
                <h5 class="card-title">Certification Title 2</h5>
                <p class="card-text">Description of the certification.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="card">
              <img
                src="certification3.jpg"
                class="card-img-top"
                alt="Certification 3"
              />
              <div class="card-body">
                <h5 class="card-title">Certification Title 3</h5>
                <p class="card-text">Description of the certification.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimonial />
    </>
  );
};

export default AboutPage;
