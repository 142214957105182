import React from "react";
import Banner from "../Banner";
import Bg1 from "../../asset/img/banner/page-title.jpg";
import BlogImg1 from "../../asset/img/blog/01.jpg";
import BlogImg2 from "../../asset/img/blog/02.jpg";
import BlogImg3 from "../../asset/img/blog/03.jpg";
import SmImg1 from "../../asset/img/blog/sm-01.jpg";
import SmImg2 from "../../asset/img/blog/sm-02.jpg";
import SmImg3 from "../../asset/img/blog/sm-03.jpg";
import { Link } from "react-router-dom";
import Header from "../Header/Header";


const Blogdefault = () => {
  return (
    <>
    <Header/>
      <Banner img={Bg1} heading="Blog Default" name="Blog Default" />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 sm-margin-50px-bottom xs-margin-30px-bottom">
              <div className="blog-list-simple">
                <div className="row">
                  <div className="col-md-12 margin-20px-bottom md-margin-15px-bottom xs-margin-10px-bottom">
                    <div className="blog-list-simple-img position-relative">
                      <img src={BlogImg1} alt="BlogImg" />
                      <div className="date">
                        <span className="display-block">17</span>
                        <span className="font-size14 font-weight-400">Mar</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="blog-list-simple-text">
                      <ul className="meta">
                        <li>
                            <Link>
                            <i aria-hidden="true" className="fa fa-user"></i>{" "}
                            Roger
                            </Link>
                         
                        </li>
                        <li>
                            <Link>
                            <i aria-hidden="true" className="fa fa-tags"></i>{" "}
                            blog
                            </Link>
                          
                        </li>
                        <li>
                            <Link>
                            <i
                              aria-hidden="true"
                              className="fa fa-comments"
                            ></i>{" "}
                            0 Comments
                            </Link>
                        
                        </li>
                      </ul>
                      <h4>
                        <Link>
                        How to go about being an start-up in a matter.
                        </Link>
                        
                      </h4>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal.
                      </p>
                      <div className="text-left margin-10px-top">
                        <Link className="butn small">
                        <span>Read More</span>
                        </Link>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-list-simple">
                <div className="row">
                  <div className="col-md-12 margin-20px-bottom md-margin-15px-bottom xs-margin-10px-bottom">
                    <div className="blog-list-simple-img position-relative">
                      <img src={BlogImg2}  alt="BlogImg2"/>
                      <div className="date">
                        <span className="display-block">18</span>
                        <span className="font-size14 font-weight-400">Feb</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="blog-list-simple-text">
                      <ul className="meta">
                        <li>
                            <Link>
                            <i aria-hidden="true" className="fa fa-user"></i>{" "}
                            Tom
                            </Link>
                        
                        </li>
                        <li>
                            <Link>
                            <i aria-hidden="true" className="fa fa-tags"></i>{" "}
                            blog
                            </Link>
                          
                        </li>
                        <li>
                            <Link>
                            <i
                              aria-hidden="true"
                              className="fa fa-comments"
                            ></i>{" "}
                            0 Comments
                            </Link>
                          
                        </li>
                      </ul>
                      <h4>
                        <Link>
                        Take action & grow the profession
                        </Link>
                        
                      </h4>
                      <p>
                        All the Lorem Ipsum generators on the Internet tend to
                        repeat predefined chunks as necessary, making this the
                        first true generator on the Internet.
                      </p>
                      <div className="text-left margin-10px-top">
                        <Link className="butn small">
                        <span>Read More</span>
                        </Link>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-list-simple">
                <div className="row">
                  <div className="col-md-12 margin-20px-bottom md-margin-15px-bottom xs-margin-10px-bottom">
                    <div className="blog-list-simple-img position-relative">
                      <img alt="" src={BlogImg3} />
                      <div className="date">
                        <span className="display-block">20</span>
                        <span className="font-size14 font-weight-400">Feb</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="blog-list-simple-text">
                      <ul className="meta">
                        <li>
                            <Link>
                            <i aria-hidden="true" className="fa fa-user"></i>{" "}
                            Randall
                            </Link>
                         
                        </li>
                        <li>
                            <Link>
                            <i aria-hidden="true" className="fa fa-tags"></i>{" "}
                            blog
                            </Link>
                         
                        </li>
                        <li>
                            <Link>
                            <i
                              aria-hidden="true"
                              className="fa fa-comments"
                            ></i>{" "}
                            0 Comments
                            </Link>
                          
                        </li>
                      </ul>
                      <h4>
                        <Link>
                        High standard work for our clients.
                        </Link>
                        
                      </h4>
                      <p>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum as their default model text, and a
                        search for 'lorem ipsum' will uncover many web sites
                        still in their infancy.
                      </p>
                      <div className="text-left margin-10px-top">
                        <Link  className="butn small">
                        <span>Read More</span>
                        </Link>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="no-margin">
                <div className="pagination text-small text-uppercase text-extra-dark-gray">
                  <ul className="no-margin">
                    <li>
                        <Link>
                        <i className="fas fa-long-arrow-alt-left margin-5px-right xs-display-none"></i>{" "}
                        Prev
                        </Link>
                     
                    </li>
                    <li className="active">
                        <Link>
                        1
                        </Link>
                    
                    </li>
                    <li>
                    <Link>
                        2
                        </Link>
                    </li>
                    <li>
                    <Link>
                        3
                        </Link>
                    </li>
                    <li>
                        <Link>
                        Next{" "}
                        <i className="fas fa-long-arrow-alt-right margin-5px-left xs-display-none"></i>
                        </Link>
                      
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="side-bar padding-30px-left md-padding-5px-left sm-no-padding-left">
                <div className="widget search">
                  <form method="post">
                    <input type="hidden" name="form-name" value="form 1" />
                    <input
                      type="search"
                      name="search"
                      placeholder="Type here ..."
                    />
                    <button type="submit" className="butn">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Categories</h6>
                  </div>
                  <ul className="no-margin-bottom">
                    <li>
                        <Link>
                        Manufacturing
                        </Link>
                      
                    </li>
                    <li>
                        <Link>
                        Chemical Research
                        </Link>
                     
                    </li>
                    <li>
                        <Link>
                        Mechanical
                        </Link>
                   
                    </li>
                    <li>
                        <Link>
                        Building Management
                        </Link>
                      
                    </li>
                    <li>
                        <Link>
                        Electronic Materials
                        </Link>
                   
                    </li>
                  </ul>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Recent Posts</h6>
                  </div>
                  <div className="recent-post width-100 border-bottom margin-15px-bottom sm-margin-10px-bottom padding-15px-bottom sm-padding-10px-bottom">
                    <div className="width-30 sm-width-auto float-left">
                        <Link>
                        <img src={SmImg1} alt="product thumbnail" />
                        </Link>
                     
                    </div>
                    <div className="width-70 display-inline-block padding-15px-left">
                      <h6 className="font-size14 margin-5px-bottom font-weight-600">
                        <Link>
                        Reach your target audience and ultimately bring
                        </Link>
                        
                      </h6>
                      <span className="font-size13">February 18, 2020</span>
                    </div>
                  </div>

                  <div className="recent-post width-100 border-bottom margin-15px-bottom sm-margin-10px-bottom padding-15px-bottom sm-padding-10px-bottom">
                    <div className="width-30 sm-width-auto float-left">
                        <Link>
                        <img src={SmImg2} alt="product thumbnail" />
                        </Link>
                     
                    </div>
                    <div className="width-70 display-inline-block padding-15px-left">
                      <h6 className="font-size14 margin-5px-bottom">
                        <Link>
                        Everything You Need To Know About construction
                        </Link>
                       
                      </h6>
                      <span className="font-size13">january 24, 2020</span>
                    </div>
                  </div>

                  <div className="recent-post width-100">
                    <div className="width-30 sm-width-auto float-left">
                        <Link>
                        <img src={SmImg3} alt="product thumbnail" />
                        </Link>
                     
                    </div>
                    <div className="width-70 display-inline-block padding-15px-left">
                      <h6 className="font-size14 margin-5px-bottom">
                        <Link>
                        How Frontend Developers Can Help To Bridge
                        </Link>
                        
                      </h6>
                      <span className="font-size13">January 28, 2020</span>
                    </div>
                  </div>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Archives</h6>
                  </div>
                  <ul className="no-margin-bottom">
                    <li>
                        <Link>
                        January 2020
                        </Link>
                     
                    </li>
                    <li>
                        <Link>
                        February 2020
                        </Link>
                    
                    </li>
                    <li>
                        <Link>
                        March 2020
                        </Link>
                      
                    </li>
                  </ul>
                </div>
                <div className="widget">
                  <div className="widget-title">
                    <h6>Follow Us</h6>
                  </div>
                  <ul className="social-listing">
                    <li>
                        <Link>
                        <i className="fab fa-facebook-f"></i>
                        </Link>
                     
                    </li>
                    <li>
                        <Link>
                        <i className="fab fa-twitter"></i>
                        </Link>
                     
                    </li>
                    <li>
                        <Link>
                        <i className="fab fa-youtube"></i>
                        </Link>
                      
                    </li>
                    <li>
                        <Link>
                        <i className="fab fa-linkedin-in"></i>
                        </Link>
                     
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogdefault;
