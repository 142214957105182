import React from "react";
import "../pages/Contactus.css";
import { Link } from "react-router-dom";
import banner1 from "../../asset/img/banner/page-title.jpg";
import Header from "../Header/Header";
const Contactus = () => {
  return (
    <>
      <Header />

      <section
        className="page-title-section bg-img cover-background "
        data-overlay-dark="75"
        style={{
          backgroundImage: `url(${banner1})`,
          // Adjust the margin-top value as needed
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Contact Us</h1>
            </div>
            <div className="col-md-12">
              <ul>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link>Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 sm-margin-25px-bottom">
              <div className="padding-30px-all sm-padding-25px-all xs-padding-20px-all bg-light-gray text-center">
                <i className="ti-email font-size40 sm-font-size38 xs-font-size34 text-theme-color"></i>
                <h5 className="margin-5px-bottom margin-20px-top sm-margin-15px-top font-size20 xs-font-size18">
                  Email Us:
                </h5>
                <p className="no-margin-bottom">infosubhasavilla@gmail.com</p>
              </div>
            </div>
            <div className="col-lg-4 sm-margin-25px-bottom">
              <div className="padding-30px-all sm-padding-25px-all xs-padding-20px-all bg-light-gray text-center">
                <i className="ti-mobile font-size40 sm-font-size38 xs-font-size34 text-theme-color"></i>
                <h5 className="margin-5px-bottom margin-20px-top sm-margin-15px-top font-size20 xs-font-size18">
                  Call Us:
                </h5>
                <p className="no-margin-bottom">123456789</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="padding-30px-all sm-padding-25px-all xs-padding-20px-all bg-light-gray text-center">
                <i className="ti-location-pin font-size40 sm-font-size38 xs-font-size34 text-theme-color"></i>
                <h5 className="margin-5px-bottom margin-20px-top sm-margin-15px-top font-size20 xs-font-size18">
                  Our Address:
                </h5>
                <p className="no-margin-bottom">
                  House No 8,Kasturba Path ,Boring Road(Patna)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="no-padding-top" >
        <div className="container" >
          <div className="padding-40px-all sm-padding-30px-all xs-padding-20px-all "style={{backgroundColor:"#b7ecbc",color:"white"}} >
            <h4 className="font-size38 sm-font-size34 xs-font-size28 line-height-45 sm-line-height-40 font-weight-500 margin-10px-bottom text-center">
              Contact Us
            </h4>
            <p className="margin-50px-bottom sm-margin-30px-bottom xs-margin-25px-bottom width-60 md-width-70 sm-width-auto center-col text-center">
              Get in Touch with Us .
            </p>
            <form action="email.php" method="post" id="contact-form">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      name="name"
                      placeholder="Enter your name"
                      type="text"
                      className="no-margin-bottom"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      className="no-margin-bottom"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                      name="number"
                      placeholder="Enter your mobile number"
                      type="number"
                      className="no-margin-bottom"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      type="text"
                      placeholder="Enter your subject"
                      name="subject"
                      className="no-margin-bottom"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12 margin-20px-bottom sm-margin-10px-bottom">
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      name="message"
                      placeholder="Enter your message"
                      className="form-control line-height-70"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="Send">
                    <button className="butn" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <p className="form-messege"></p>
          </div>
        </div>
      </section>

      {/* <div className="col-lg-12">
              <div className="padding-40px-all sm-padding-30px-all xs-padding-20px-all bg-light-gray">
                <h4 className="font-size38 sm-font-size34 xs-font-size28 line-height-45 sm-line-height-40 font-weight-500 margin-10px-bottom text-center">
                 Contact us
                </h4>
                <p className="margin-50px-bottom sm-margin-30px-bottom xs-margin-25px-bottom width-60 md-width-70 sm-width-auto center-col text-center" style={{color:"red"}}>
                Get in Touch with Us

                 
                </p>
                <form
                  action="email.php"
                  method="post"
                  id="career-form"
                  encType="multipart/form-data"
                >
                  <div className="form-group"  style={{marginBottom:"0px",marginTop: "-11px"}}>
                    <label>Name</label>
                    <input
                      name="name"
                      placeholder="Enter your name"
                      type="text"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group" style={{marginBottom:"0px",marginTop: "-11px"}}>
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group" style={{marginBottom:"0px",marginTop: "-11px"}}>
                    <label>Mobile Number</label>
                    <input
                      type="number"
                      name="number"
                      placeholder="Enter your number"
                      className="form-control"
                      required
                    />
                  </div>

                  <div className="col-lg-12" style={{marginLeft:"-12px",marginTop: "-11px"}}>
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input  type="text" name="subject" className="no-margin-bottom" />
                                </div>
                                <div className="col-lg-12 margin-20px-bottom sm-margin-10px-bottom" style={{marginLeft:"-12px",marginTop: "-11px"}}>
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea  name="message" className="form-control line-height-70"></textarea>
                                </div>
                            </div>

                  <div className="text-center">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                  </div>
                </form>
                <p className="form-message"></p>
              </div>
            </div> */}
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7125168.022058162!2d70.68733554999999!3d29.309154400000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1625513723843!5m2!1sen!2s"  width="1800"
      height="450"
      style={{ border: '0' }}
      allowFullScreen
      loading="lazy"
     
      title="your-iframe-title"></iframe> */}
    </>
  );
};

export default Contactus;
