import React from "react";
import databg from "../../asset/img/banner/slider-1.jpg";
import { Link } from "react-router-dom";


const BannerSection = () => {
  return (
    <>
      <section
        className="bg-img banner-section cover-background full-screen"
        data-overlay-dark="5"
        style={{ backgroundImage: `url(${databg})`,padding:"40px" }}
      >
        <div className="container h-100 z-index-9">
          <div className="display-table h-100 w-100">
            <div className="display-table-cell vertical-align-middle">
              <div className="row">
                <div className="col-lg-7 sm-margin-40px-bottom our" >
                  <h1 className="font-size50 md-font-size42 sm-font-size38 xs-font-size30 font-weight-800 text-white" style={{marginTop:"100px"}}>
                    Our Best Construction Services
                  </h1>
                  <p className="width-95 xs-width-100 font-size16 xs-display-none line-height-30 xs-font-size14 xs-line-height-26 font-size20 text-white">
                    50 Years of <br />
                    Experience in Industry.
                  </p>
                  <div className="margin-30px-top sm-margin-25px-top">
                    <Link  className="butn theme white-hover">
                   
                    <span>Get Started</span>
                    </Link>
                   
                     
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="story-video display-table sm-display-inherit height-100 center-col">
                    <div className="display-table-cell vertical-align-middle">
                      <div className="display-inline-block about-video vertical-align-middle">
                        <a
                          className="video video_btn video_btn-style1 small"
                          href="video/down-time.mp4"
                        >
                          <i className="fas fa-play"></i>
                        </a>
                      </div>
                      <div className="display-inline-block vertical-align-middle font-weight-700 font-size16 xs-font-size14 margin-25px-left text-white">
                        Watch
                        <span className="text-uppercase display-block">
                          intro video
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerSection;
