import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
const ServiceCard = (props) => {
  return (
    <>
      <div className="col-lg-4 col-md-6 margin-30px-bottom xs-margin-25px-bottom">
        <div className="service-block02">
          <div>
            <img src={props.img} alt="" />
          </div>
          <div className="padding-25px-all md-padding-20px-all">
            <h5 className="font-weight-500 font-size22 md-font-size18 md-margin-10px-bottom xs-margin-10px-bottom">
              <Link  className="text-black"></Link>
             
                {props.title}
              
            </h5>
            <p className="text">{props.name}</p>
            <Link className="text-black font-weight-600 font-size14 md-font-size13">
              Read More 
              <FontAwesomeIcon icon={faArrowRight} style={{marginLeft:"10px"}} />           
               </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
