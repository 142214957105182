import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const TopNav = () => {
  return (
    <>
      <div id="top-bar" className="top-bar" style={{ backgroundColor: "#000000c2" }}>
        <div className="container lg-container">
          <div className="row">
            <div className="col-md-3 xs-display-none">
              <ul className="top-social-icon">
                <li>
                  <Link>
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li>
                  <Link>
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link>
                    <i className="fab fa-pinterest-p"></i>
                  </Link>
                </li>
                <li>
                  <Link>
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </li>
                <li>
                  <Link>
                    <i className="fab fa-dribbble"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-md-9">
              <div className="top-bar-info">
                <ul>
                  <li>
                    <Link style={{ color: "white" }}>
                      <FontAwesomeIcon icon={faPhone} /> 123456
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "white" }}>
                      <FontAwesomeIcon icon={faEnvelope} /> infosubhasalvilla.in
                    </Link>
                  </li>
                  <li className="sm-display-none">
                    <i className="far fa-clock"></i>Mon-Fri: 7:00 - 17:00
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;
