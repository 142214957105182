import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-style3-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 sm-margin-20px-bottom">
                <h5 className="margin-5px-bottom text-white">
                  Subscribe to our Newsletter!
                </h5>
                <p>Sign up for our newsletter and follow us on social media.</p>
              </div>
              <div className="col-md-5">
                <div className="email-box">
                  <div className="input">
                    <form>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        className="no-margin-bottom"
                      />
                      <button className="butn">Subscribe</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-5 footer-style3">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <h4 className="mobile-margin-10px-bottom">Quick Contact</h4>
                <ul className="">
                  <li>
                    <i className="ti-location-pin margin-10px-right md-margin-two-right text-theme-color"></i>
                    House No-8 ,Kasturba Path,Boring Road(Patna){" "}
                  </li>
                  <li>
                    <Link className="font-size14">
                      <i className="ti-email margin-10px-right md-margin-two-right text-theme-color"></i>
                      infosubhasavilla@gmail.com
                    </Link>
                  </li>
                  <li>
                    <i className="ti-mobile margin-10px-right md-margin-two-right text-theme-color"></i>
                    12345678
                  </li>
                  <li>
                    <Link className="font-size14">
                      <i className="ti-time margin-10px-right md-margin-two-right text-theme-color"></i>
                      Mon-Fri: 7:00 - 17:00
                    </Link>
                  </li>
                </ul>

                <div className="footer-icon">
                  <ul className="no-margin-bottom">
                    <li>
                      <Link>
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fab fa-youtube "></i>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <i className="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 sm-margin-25px-bottom mobile-margin-20px-bottom">
                <div className="padding-70px-left md-padding-50px-left sm-no-padding-left">
                  <h4 className="mobile-margin-10px-bottom">Our Services</h4>
                  <ul className="footer-list no-margin-bottom">
                    <li>
                      <Link className="font-size14">Building Construction</Link>
                    </li>
                    <li>
                      <Link className="font-size14">House Construction</Link>
                    </li>
                    <li>
                      <Link className="font-size14">Interior Design </Link>
                    </li>
                    <li>
                      <Link className="font-size14">Floors and Roof</Link>
                    </li>
                    <li>
                      <Link className="font-size14">Plumbing Construction</Link>
                    </li>
                    <li>
                      <Link className="font-size14">Electricity</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 mobile-margin-20px-bottom">
                <div className="padding-40px-left sm-no-padding-left">
                  <h4 className="mobile-margin-10px-bottom">Quick Links</h4>
                  <ul className="footer-list no-margin-bottom">
                    <li>
                      <Link className="font-size14">About us</Link>
                    </li>
                    {/* <li>
                      <Link className="font-size14">Services</Link>
                    </li> */}
                    <li>
                      <Link className="font-size14">Product</Link>
                    </li>
                    <li>
                      <Link className="font-size14">Gallery</Link>
                    </li>
                    <li>
                      <Link className="font-size14">Career</Link>
                    </li>
                    <li>
                      <Link className="font-size14">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 sm-margin-25px-bottom mobile-margin-20px-bottom">
                <div className="sm-padding-50px-right xs-no-padding-right">
                  <span className="footer-logo margin-25px-bottom xs-margin-15px-bottom display-inline-block">
                    <img
                      src="img/logos/logo-footer-small-white.png"
                      alt="logo"
                    />
                  </span>
                  <p className="no-margin-bottom">
                    Lorem ipsum dolor consectetur consectetur adipiscing ipsum
                    dolor consectetur adipiscing sed et eletum nulla.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-style3-bottom">
          <div className="container">
            <p className="font-size14">&copy; 2022 Powered by Subhasavilla</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
