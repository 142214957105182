import React from "react";
import Banner from "../Banner";
import Img from "../../asset/img/banner/page-title.jpg";
import { Link } from "react-router-dom";
import Header from "../Header/Header";


const FaqPage = () => {
  return (
    <>
    <Header/>
      <Banner img={Img} heading="Faq" name="Faq" />

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div
                id="accordion"
                className="accordion-style padding-30px-right md-padding-15px-right"
              >
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What Do You Know About Interior?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How can I make a change to my application?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      Combined with a handful of model sentence structures, to
                      generate Lorem Ipsum which looks reasonable. The generated
                      Lorem Ipsum is therefore always free from repetition
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Basic Part Of Property For Human Life ?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      Many desktop publishing packages and web page editors now
                      use Lorem Ipsum as their default model text, and a search
                      for 'lorem ipsum' will uncover
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingFour">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Where can I find out about funding?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      It was popularised in the 1960s with the release of
                      Letraset sheets containing Lorem Ipsum passages, and more
                      recently with desktop publishing
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="sm-display-none">
                <img src="img/content/faq-img1.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="padding-40px-all sm-padding-30px-all xs-padding-20px-all bg-light-gray margin-80px-top sm-margin-40px-top xs-margin-30px-top">
            <h4 className="font-size38 sm-font-size34 xs-font-size28 line-height-45 sm-line-height-40 font-weight-500 margin-10px-bottom text-center">
              Contact Us
            </h4>
            <p className="margin-50px-bottom sm-margin-30px-bottom xs-margin-25px-bottom width-60 md-width-70 sm-width-auto center-col text-center">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ainim veniam.
            </p>
            <form>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="no-margin-bottom"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="no-margin-bottom"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      id="name-01"
                      type="text"
                      name="subject"
                      className="no-margin-bottom"
                    />
                  </div>
                </div>
                <div className="col-lg-12 margin-20px-bottom sm-margin-10px-bottom">
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      id="text"
                      name="message"
                      className="form-control line-height-70"
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="Send">
                    <Link className="butn">
                    Send Message
                    </Link>
                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqPage;
